import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { getAicromiaBookingStatistic } from '../service/statistics';
import StatisticSection from '../components/section/StatisticSection';
import { Statistics } from '../types/api';

function Home() {
  const { user } = useAuth();

  // Statistiche
  const [aicromiaBookingStat, setAicromiaBookingStat] = useState<Statistics>();

  const getStatus = async () => {

    const { response, error } = await getAicromiaBookingStatistic();
    if (error) {
      console.log('error', error);

      return;
    }

    if (response) {
      console.log('response', response);
      setAicromiaBookingStat(response.data);
    }

  }

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <div className='page'>
      <div className='flex items-center justify-center flex-col'>
        <h1 className='text-4xl my-2'>Ciao, {user?.email}</h1>

        <section className='grid my-8'>
          <h2 className='text-2xl mb-2 text-center font-semibold'>Statistiche</h2>
          <StatisticSection />

          <div className='bg-slate-100 p-6 rounded-md'>

            <h3 className='text-xl font-medium mb-2'>Slot Aicromia Booking</h3>
            <div className='bg-white p-4 rounded-sm'>
              <p><span className='font-bold text-4xl'>{aicromiaBookingStat?.allAicromiaBooking}</span><span className='opacity-75 ml-2'>TOTALI</span></p>
            </div>
            <div className='bg-white p-4 rounded-sm'>
              <p><span className='font-bold text-4xl'>{aicromiaBookingStat?.aicromiaBookingVerified}</span><span className='opacity-75 ml-2'>VERIFICATI</span></p>
            </div>
            <div className='bg-white p-4 rounded-sm'>
              <p><span className='font-bold text-4xl'>{aicromiaBookingStat?.aicromiaBookingNotVerified}</span><span className='opacity-75 ml-2'>NON VERIFICATI</span></p>
            </div>
            <div className='bg-white p-4 rounded-sm'>
              <p><span className='font-bold text-4xl'>{aicromiaBookingStat?.aicromiaBookingImagesNotValid}</span><span className='opacity-75 ml-2'>IMMAGINI NON VALIDE</span></p>
            </div>

            <h3 className='text-xl font-medium mt-4'>Utenti</h3>
            <div className='bg-white p-4 rounded-sm'>
              <p><span className='font-bold text-4xl'>{aicromiaBookingStat?.allUser}</span><span className='opacity-75 ml-2'>TOTALI</span></p>
            </div>
            <div className='bg-white p-4 rounded-sm'>
              <p><span className='font-bold text-4xl'>{aicromiaBookingStat?.allUserVerified}</span><span className='opacity-75 ml-2'>CON INFORMAZIONI COMPLETE</span></p>
            </div>
            <div className='bg-white p-4 rounded-sm'>
              <p><span className='font-bold text-4xl'>{aicromiaBookingStat?.allUserNewsletter}</span><span className='opacity-75 ml-2'>NEWSLETTER</span></p>
            </div>

            <h3 className='text-xl font-medium mt-4'>Wardrobe</h3>
            <div className='bg-white p-4 rounded-sm'>
              <p><span className='font-bold text-4xl'>{aicromiaBookingStat?.allWardrobe}</span><span className='opacity-75 ml-2'>TOTALI</span></p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
