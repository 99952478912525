import React from 'react';

// Router
import { Route, Routes } from 'react-router-dom';
import DashboardWrapper from './components/wrapper/DashboardWrapper';
import AicromiaBooking from './pages/AicromiaBooking';
import Home from './pages/Home';

//Pages
import Login from './pages/Login';
import PrivateRoute from './route/PrivateRoute';
import AicromiaBookingDetail from './pages/AicromiaBookingDetail';
import Aicromia from './pages/Aicromia';
import AicromiaDetail from './pages/AicromiaDetail';
import Statistic from './pages/Statistic';
import Faceshape from './pages/Faceshape';
import FaceshapeDetail from './pages/FaceshapeDetail';
import AicromiaBookingVerified from './pages/AicromiaBookingVerified';
import Profile from './pages/Profile';
import ProfileDetail from './pages/ProfileDetail';

function App() {
  return (
    <main className=''>
      <Routes>
        <Route
          path='/'
          element={
            <PrivateRoute>
              <DashboardWrapper />
            </PrivateRoute>
          }
        >
          <Route path='/' element={<Home />} />
          <Route path='/aicromia-booking' element={<AicromiaBooking />} />
          <Route path='/aicromia-booking/verified' element={<AicromiaBookingVerified />} />
          <Route path='/aicromia' element={<Aicromia />} />
          <Route
            path='/aicromia-booking/:id'
            element={<AicromiaBookingDetail />}
          />
          <Route
            path='/aicromia/:id'
            element={<AicromiaDetail />}
          />
          <Route
            path='statistic'
            element={<Statistic />}
          />

          <Route
            path='faceshape'
            element={<Faceshape />}
          />

          <Route
            path='/faceshape/:id'
            element={<FaceshapeDetail />}
          />

          <Route
            path='profile'
            element={<Profile />}
          />

          <Route
            path='/profile/:id'
            element={<ProfileDetail />}
          />
        </Route>

        <Route path='/login' element={<Login />} />
      </Routes>
    </main>
  );
}

export default App;
