import React from 'react';
import { AicromiaBookingResponse } from '../../types/api';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Badge, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';

function AicromiaBookingVerifiedCard({
  season,
  seasonImages,
  _id,
  userDetail,
  updatedAt
}: AicromiaBookingResponse) {

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div className='flex mb-4 justify-between md:flex-row flex-col items-center space-x-2 space-y-2'>


      <div className='flex md:flex-row flex-col w-full  space-x-4  rounded-lg bg-slate-100 p-4 hover:shadow-md'>
        <img
          alt={'Image aicromia'}
          src={seasonImages[0].imageUrl} className='h-[12vh] w-[12vh] rounded-md' />
        <div className='flex flex-col m-2'>
          <p>
            Stagione: <span className='font-bold'>{season.seasonName}</span>
          </p>
          <p>
            Sotto Stagione:{' '}
            <span className='font-bold'>{season.underseason}</span>
          </p>
          <p>Verificato il: {
            // date
            new Date(updatedAt).toLocaleDateString('it-IT', {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
            })
          }</p>
          {
            season.confidence &&

            <div className='flex items-center space-x-2'>
              <span>Confidence: {season.confidence.toPrecision(2)}</span>
              <div
                className={clsx(
                  parseFloat(season.confidence.toPrecision(2)) <= 0.25 && 'red',
                  parseFloat(season.confidence.toPrecision(2)) > 0.25 &&
                  parseFloat(season.confidence.toPrecision(2)) < 0.5 &&
                  'yellow',
                  parseFloat(season.confidence.toPrecision(2)) >= 0.5 &&
                  parseFloat(season.confidence.toPrecision(2)) < 0.75 &&
                  'lightgreen',
                  parseFloat(season.confidence.toPrecision(2)) >= 0.75 && 'green',

                  'rounded-full'
                )}
              >
                <progress value={season.confidence.toPrecision(2)} max={1} />
              </div>
            </div>
          }
        </div>
        <div className='m-2'>
          <p>{userDetail.name} {userDetail.surname}</p>
          <p>{userDetail.email}</p>
          <p>{userDetail.season?.seasonName} {userDetail.season?.underseason}</p>
          <Button
            onClick={onOpen}
            size={'xs'} colorScheme='purple'>
            Più Dettagli
          </Button>
        </div>

      </div>
      <div className=' flex justify-center'>
        <Link className='underline text-center' to={'/aicromia-booking/' + _id}>
          Visualizza Foto
        </Link>
      </div>


      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Info utente</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className='flex flex-col gap-2 rounded-md border py-4'>
              <p className='mx-2'><b>Id:</b> {userDetail._id}</p>
              <p className='mx-2'><b>Nome e cognome:</b> {userDetail.name} {userDetail.surname}</p>
              <p className='mx-2'><b>Email:</b> {userDetail.email} {userDetail.emailVerified ? <Badge fontSize={'xs'} colorScheme='green'>Verificata</Badge> : <Badge fontSize={'xs'} colorScheme='red'>Non verificata</Badge>}</p>
              <p className='mx-2'><b>Moda:</b> {userDetail.fashionPreference}</p>
              <p className='mx-2'><b>Stagione corrente:</b> {userDetail.season?.seasonName} {userDetail.season?.underseason}</p>
              <p className='mx-2'><b>Indirizzo:</b> {userDetail?.address?.city}, {userDetail?.address?.country}, {userDetail?.address?.line1}, {userDetail?.address?.postal_code}, {userDetail?.address?.state}</p>
              <p className='mx-2'><b>Newsletter:</b> <Badge fontSize={'xs'} colorScheme={userDetail.newsletter ? 'green' : 'red'}>{userDetail.newsletter ? 'Iscritto' : 'Non iscritto'}</Badge></p>
              <p className='mx-2'><b>Pacchetti attivi:</b> {userDetail.activeFunctionality.map((a) => {
                return <Badge fontSize={'xs'} colorScheme={'green'}>{a}</Badge>
              })}</p>



            </div>

          </ModalBody>

          <ModalFooter>
            <Button variant={'ghost'} mr={3} onClick={onClose}>
              Chiudi
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>

  );
}

export default AicromiaBookingVerifiedCard;
