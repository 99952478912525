import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FormEvent, useState } from 'react';
import { useAuth } from '../context/AuthContext';

export default function Login() {
  const toast = useToast();
  const navigate = useNavigate();

  // Context
  const { login, user } = useAuth();

  // State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmiting] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmiting(true);

    try {
      let res = await login(email, password);
      if (!res) {
        toast({
          title: 'Errore login',
          description: 'Credenziali errate',
          status: 'error',
          duration: 1500,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Login effettuato',
          description: 'Benvenuto',
          status: 'success',
          duration: 1500,
          isClosable: true,
        });
        setIsSubmiting(false);
        navigate('/');
      }
    } catch (error) {
      console.log(error);
    }

    console.log(user);
    setIsSubmiting(false);
  };

  return (
    <div className='bg-purple-500 '>
      <div className='mx-4 md:mx-0 grid min-h-screen grid-cols-1  place-content-center place-items-center md:grid-cols-2 '>
        <form
          onSubmit={(e) => handleSubmit(e)}
          className=' w-full max-w-lg rounded-lg  bg-white p-4 shadow-lg'
        >
          <Stack spacing={4} w={'full'} maxW={'lg'}>
            <Heading textAlign={'center'} fontSize={'2xl'}>
              Login su Khooa Backoffice
            </Heading>

            <FormControl id='email'>
              <FormLabel>Email</FormLabel>
              <Input
                isRequired
                onChange={(e) => setEmail(e.target.value)}
                type='email'
              />
            </FormControl>
            <FormControl id='password'>
              <FormLabel>Password</FormLabel>
              <Input
                isRequired
                onChange={(e) => setPassword(e.target.value)}
                type='password'
              />
            </FormControl>
            <Stack spacing={6}>
              <Button
                isLoading={isSubmitting}
                type='submit'
                colorScheme={'primary'}
                variant={'solid'}
              >
                Login
              </Button>
            </Stack>
          </Stack>
        </form>
        <div className='hidden md:block h-screen w-full'>
          <img
            className='w-full h-screen object-cover'
            src='https://source.unsplash.com/random/900×700/?fashion'
          />
        </div>
      </div>
    </div>
  );
}
