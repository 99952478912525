import React from 'react';
import { AicromiaBookingResponse, FaceshapeFrontResponse } from '../../types/api';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { DeleteIcon } from '@chakra-ui/icons';
import { deleteFaceshape } from '../../service/faceshape';
import { toast } from 'react-hot-toast';
import { Button } from '@chakra-ui/react';

function FaceshapeItem({
  _id,
  faceShape,
  imageUrl,
  reloadData
}: FaceshapeFrontResponse & { reloadData: () => Promise<void> }) {

  const [deleting, setDeleting] = React.useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    const { response, error } = await deleteFaceshape(_id);
    if (error) {
      setDeleting(false);
      return;
    }
    if (response) {
      toast.success('Eliminato con successo');
      await reloadData();
      setDeleting(false);
    }
  };


  return (
    <div className='flex justify-between'>
      <Link to={'/faceshape/' + _id} className='w-full'>
        <div className='flex justify-between items-center w-full  space-x-4  rounded-lg bg-slate-100 p-4 hover:shadow-md'>
          <img
            alt={'Image aicromia'}
            src={imageUrl} className='h-[12vh] w-[12vh] rounded-md' />

        </div>
      </Link>

      <Button
        isLoading={deleting}
        colorScheme='red'
        color={'white'}
        backgroundColor={'red'}
        onClick={() => {
          if (window.confirm('Sei sicuro di voler eliminare?')) {
            handleDelete();
          }
        }
        }
        className='flex text-white cursor-pointer items-center mx-2 rounded-md px-8 bg-red-500'>
        {/** DELETE */}
        <span>
          <DeleteIcon

            className='text-lg'
          />
        </span>

      </Button>
    </div>
  );
}

export default FaceshapeItem;
