import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  getAicromiaById,
  saveAicromiaBookingSeason,
} from '../service/aicromia';
import { toast } from 'react-hot-toast';
import { MyError } from '../utils/MyError';
import { AicromiaResponse } from '../types/api';
import { Button, Checkbox, Skeleton, useDisclosure, Stack } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Image
} from '@chakra-ui/react';

import { MdOutlineKeyboardDoubleArrowDown } from 'react-icons/md';
import { Season } from '../types/generic';
import clsx from 'clsx';

function AicromiaBookingDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AicromiaResponse>();
  const [selectedResult, setSelectedResult] = useState<Season>();
  const [training, setTraining] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getData = async () => {
    setLoading(true);
    if (!id) {
      setLoading(false);
      return;
    }

    const { response, error } = await getAicromiaById(id);
    if (error) {
      if (error instanceof MyError) {
        toast.error(error.message);
      } else {
        toast.error('Errore');
      }
      setLoading(false);
      return;
    }

    if (response) {
      setData(response.data);
      setLoading(false);
    }

    setLoading(false);
  };

  const handleConfirmResponseModal = (season: Season) => {
    setSelectedResult(season);
    onOpen();
  };

  const submitConfirmResponse = async () => {
    setIsSubmitting(true);

    if (!id) return;
    if (!selectedResult) return;

    const { response, error } = await saveAicromiaBookingSeason(
      id,
      training,
      selectedResult
    );

    if (error) {
      if (error instanceof MyError) {
        toast.error(error.message);
        setIsSubmitting(false);
        return;
      } else {
        toast.error('Errore');
        setIsSubmitting(false);
        return;
      }
    }

    if (response) {
      toast.success('Risposta inviata correttamente');
      navigate('/aicromia');
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className='w-full border p-4'>
      {loading && !data ? (
        <div className='m-4'>
          <Skeleton height='70px' />
        </div>
      ) : (
        <div className='relative'>
          <div className=' sticky top-0 z-10 h-full bg-white  bg-opacity-70 pb-2 backdrop-blur-sm'>
            <div className=' sticky top-0 z-10 h-full'>
              <Link className=' underline' to={'/aicromia-booking'}>
                Indietro
              </Link>
            </div>
            <h3 className='text-center text-xl font-bold'>Dettaglio</h3>
            <div className=' flex flex-col items-center justify-center'>
              <p className=''>
                Risultato AI: {data?.season.seasonName}{' '}
                {data?.season.underseason}
              </p>
              <p>Confidence: {data?.season.confidence}</p>
            </div>
          </div>

          <div className='m-2'>
            <Stack className='justify-center' spacing={8} direction='row'>

              {data?.seasonImages.map(
                (d, i) => {
                  return (
                    <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden'>
                      <Image src={d.imageUrl} alt={"image"} />
                      <Box p='6'>
                        <Box
                          mt='1'
                          fontWeight='semibold'
                          as='h4'
                          lineHeight='tight'
                          noOfLines={1}
                        >
                          Immagine {i + 1}
                        </Box>
                      </Box>

                      <div className='flex items-center space-x-2'>
                        <span>Confidence: {d.confidence}</span>
                        <div
                          className={clsx(
                            parseFloat(d.confidence.toPrecision(2)!) <= 0.25 && 'red',
                            parseFloat(d.confidence.toPrecision(2)!) > 0.25 &&
                            parseFloat(d.confidence.toPrecision(2)!) < 0.5 &&
                            'yellow',
                            parseFloat(d.confidence.toPrecision(2)!) >= 0.5 &&
                            parseFloat(d.confidence.toPrecision(2)!) < 0.75 &&
                            'lightgreen',
                            parseFloat(d.confidence.toPrecision(2)!) >= 0.75 && 'green',

                            'rounded-full'
                          )}
                        >
                          <progress value={d.confidence.toPrecision(2)!} max={1} />
                        </div>
                      </div>
                    </Box>
                  )
                })
              }
            </Stack>
          </div>

          {/** NEW */}



          {/** END */}
        </div>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Conferma la risposta</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className='flex flex-col items-center justify-center rounded-md border py-4'>
              <div className='flex items-center p-1'>
                <p>
                  Da:{' '}
                  <span className='rounded-full bg-red-300 p-1'>
                    {data?.season.seasonName} {data?.season.underseason}
                  </span>
                </p>
              </div>
              <div className='flex items-center justify-center'>
                <MdOutlineKeyboardDoubleArrowDown />
              </div>
              <div className='flex'>
                <p className='rounded-full p-1'>
                  A:
                  <span className='rounded-full bg-green-300 p-1'>
                    {selectedResult?.seasonName} {selectedResult?.underseason}
                  </span>
                </p>
              </div>
            </div>
            <div className='pt-4'>
              <Checkbox isChecked={training} onChange={(e) => setTraining(e.target.checked)}>
                Training
              </Checkbox>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button variant={'ghost'} mr={3} onClick={onClose}>
              Chiudi
            </Button>
            <Button
              isLoading={isSubmitting}
              onClick={submitConfirmResponse}
              colorScheme='primary'
            >
              Conferma
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default AicromiaBookingDetail;
