import React, { useEffect, useState } from 'react';
import { getAllAicromiaBookingVerified } from '../service/aicromia';
import { AicromiaBookingResponse } from '../types/api';
import { toast } from 'react-hot-toast';
import { MyError } from '../utils/MyError';
import { Badge, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Skeleton, useDisclosure } from '@chakra-ui/react';
import { Link, Outlet } from 'react-router-dom';
import { AicromiaFilterType } from '../types/generic';
import AicromiaBookingVerifiedCard from '../components/card/AicromiaBookingVerifiedCard';
import ReactPaginate from 'react-paginate';


function AicromiaBookingVerified() {

  const { isOpen, onOpen, onClose } = useDisclosure();


  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AicromiaBookingResponse[]>([]);
  const [dataFind, setDataFind] = useState<AicromiaBookingResponse[]>();
  const [activeFilter, setActiveFilter] = useState({
    id: '',
    name: '',
    surname: '',
    email: '',
  });
  const [filter, setFilter] = useState<AicromiaFilterType | null>(
    sessionStorage.getItem('filter') ? JSON.parse(sessionStorage.getItem('filter') || '') : null
  );

  const [pagination, setPagination] = useState({
    data: data,
    offset: 0,
    numberPerPage: 10,
    pageCount: 0,
    currentData: [] as AicromiaBookingResponse[]
  });


  const handlePageClick = (event: { selected: number }) => {
    console.log(event.selected)
    const selected: number = event.selected;
    const offset: number = selected * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };



  const filterData = async (reset?: boolean) => {

    setLoading(true);
    const { response, error } = await getAllAicromiaBookingVerified(
      reset ? undefined :
        filter);
    if (error) {
      console.log('error', error);
      if (error instanceof MyError) {
        toast.error(error.message);
      } else {
        toast.error('Errore');
      }
      setLoading(false);
      return;
    }
    if (response) {
      console.log('response', response);
      setData(response.data);

      setPagination((prevState) => ({
        ...prevState,
        data: response.data,
        pageCount: response.data.length / prevState.numberPerPage,
        currentData: response.data.slice(pagination.offset, pagination.offset + pagination.numberPerPage)
      }))

    }
    setLoading(false);
  };

  const findData = async () => {

    console.log(activeFilter)

    const notEmpty = Object.values(activeFilter).filter((a) => a !== '');
    if (notEmpty.length === 0) {
      toast.error('Inserire almeno un filtro');
      return;
    }

    // filter data only with combinaiton of activeFilter not empty
    const filteredData = data.filter((item) => {
      if (activeFilter.id !== '' && item.userDetail._id !== activeFilter.id) {
        return false;
      }
      if (activeFilter.name !== '' && item.userDetail.name !== activeFilter.name) {
        return false;
      }
      if (activeFilter.surname !== '' && item.userDetail.surname !== activeFilter.surname) {
        return false;
      }
      if (activeFilter.email !== '' && item.userDetail.email !== activeFilter.email) {
        return false;
      }
      return true;
    });




    if (filteredData.length === 0) {
      toast.error('Nessun utente trovato');
      return;
    }

    setDataFind(filteredData);
    onOpen();

  }


  useEffect(() => {
    filterData();
  }, []);

  useEffect(() => {

    setPagination((prevState) => ({
      ...prevState,
      pageCount: prevState.data.length / prevState.numberPerPage,

      currentData: prevState.data.slice(pagination.offset, pagination.offset + pagination.numberPerPage)
    }))
    console.log(pagination)
  }, [pagination.numberPerPage, pagination.offset, data])

  if (loading) {
    return (
      <div className='mx-4 grid grid-cols-1 gap-2'>
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
      </div>
    );
  }

  return (
    <div className='page w-full'>


      <section className='m-4'>

        <div>
          {/** FORM TO INSERT FILTER AND THEN FIND */}
          <form>
            <div className='grid grid-cols-1 md:grid-cols-4 gap-4'>
              <input
                placeholder="ID"

                value={activeFilter.id || ''}
                type="text"
                className='border border-gray-300 rounded-md p-2'
                onChange={(e) => {
                  setActiveFilter({
                    ...activeFilter,
                    id: e.target.value ? e.target.value : '',

                  });

                }}
              />
              <input
                placeholder="Nome"
                value={activeFilter.name || ''}
                type="text"
                className='border border-gray-300 rounded-md p-2'
                onChange={(e) => {
                  setActiveFilter({
                    ...activeFilter,
                    name: e.target.value ? e.target.value : '',

                  });

                }}
              />
              <input
                placeholder="Cognome"
                value={activeFilter.surname || ''}
                type="text"
                className='border border-gray-300 rounded-md p-2'
                onChange={(e) => {
                  setActiveFilter({
                    ...activeFilter,
                    surname: e.target.value ? e.target.value : '',
                  });
                }}
              />

              <input
                placeholder="Email"
                value={activeFilter.email || ''}
                type="text"
                className='border border-gray-300 rounded-md p-2'
                onChange={(e) => {
                  setActiveFilter({
                    ...activeFilter,
                    email: e.target.value ? e.target.value : '',
                  });
                }}
              />

              <button
                type='button'
                className='bg-purple-500 text-white rounded-md p-2'
                onClick={() => {

                  findData();
                }}
              >
                Cerca
              </button>

            </div>

          </form>


        </div>
      </section>

      <section className='mx-4 mt-4'>
        {pagination.currentData && pagination.currentData.map(((item, index) => (
          <AicromiaBookingVerifiedCard {...item} key={index} />
        )))
        }
      </section>



      <ReactPaginate
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        pageCount={pagination.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />





      <Outlet />


      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Risultato ricerca ({dataFind?.length})</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/** RETURN DETAILS WITH SUMMARY FOR EVERY DATEFILTER */}
            {
              dataFind && dataFind.map((item) => {
                return (
                  <details>
                    <summary className='cursor-pointer'>{item.userDetail.name} {item.userDetail.surname}</summary>
                    <div className='flex flex-col gap-2 rounded-md border py-4'>
                      <p className='mx-2'><b>Id:</b> {item.userDetail._id}</p>
                      <p className='mx-2'><b>Nome e cognome:</b> {item.userDetail.name} {item.userDetail.surname}</p>
                      <p className='mx-2'><b>Email:</b> {item.userDetail.email} {item.userDetail.emailVerified ? <Badge fontSize={'xs'} colorScheme='green'>Verificata</Badge> : <Badge fontSize={'xs'} colorScheme='red'>Non verificata</Badge>}</p>
                      <p className='mx-2'><b>Moda:</b> {item.userDetail.fashionPreference}</p>
                      <p className='mx-2'><b>Stagione corrente:</b> {item.userDetail.season?.seasonName} {item.userDetail.season?.underseason}</p>
                      <p className='mx-2'><b>Indirizzo:</b> {item.userDetail.address.city}, {item.userDetail.address.country}, {item.userDetail.address.line1}, {item.userDetail.address.postal_code}, {item.userDetail.address.state}</p>
                      <p className='mx-2'><b>Newsletter:</b> <Badge fontSize={'xs'} colorScheme={item.userDetail.newsletter ? 'green' : 'red'}>{item.userDetail.newsletter ? 'Iscritto' : 'Non iscritto'}</Badge></p>
                      <p className='mx-2'><b>Pacchetti attivi:</b> {item.userDetail.activeFunctionality.map((a) => {
                        return <Badge fontSize={'xs'} colorScheme={'green'}>{a}</Badge>
                      })}</p>

                      <Link
                        className='underline text-center' to={'/aicromia-booking/' + item._id}
                      >
                        Visualizza foto
                      </Link>

                    </div>

                  </details>
                )
              })
            }


          </ModalBody>

          <ModalFooter>
            <Button variant={'ghost'} mr={3} onClick={onClose}>
              Chiudi
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default AicromiaBookingVerified;
