import React, { useEffect, useState } from 'react';
import { AicromiaBookingResponse, ProfileResponse, FaceshapeFrontResponse } from '../types/api';
import { toast } from 'react-hot-toast';
import { MyError } from '../utils/MyError';
import { Skeleton } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import ProfileItem from '../components/card/ProfileItem';
import { getAllProfile } from '../service/profile';

function Profile() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ProfileResponse[]>([]);

  const getData = async () => {

    setLoading(true);
    const { response, error } = await getAllProfile();
    if (error) {
      console.log('error', error);
      if (error instanceof MyError) {
        toast.error(error.message);
      } else {
        toast.error('Errore');
      }
      setLoading(false);
      return;
    }
    if (response) {
      console.log('response', response);
      setData(response.data);
    }
    setLoading(false);
  };


  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <div className='mx-4 grid grid-cols-1 gap-2'>
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
      </div>
    );
  }

  return (
    <div className='page w-full'>
      <section className='grid grid-cols-1 gap-2 m-4'>
        {
          data.length === 0 && (
            <p className='text-center text-xl'>Nessun risultato</p>
          )
        }
        {data.map((item) => {
          return <ProfileItem
            reloadData={getData}
            {...item} />;
        })}
      </section>

      <Outlet />
    </div>
  );
}

export default Profile;
