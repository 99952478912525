import api from ".";
import { ApiResponse, ProfileResponse, ProfileSend, FaceshapeFrontResponse, FaceshapeFrontSend } from "../types/api";
import { MyError } from "../utils/MyError";

/** GET ALL profile */
export const getAllProfile = async (): Promise<ApiResponse<ProfileResponse[]>> => {
  try {
    let res = await api.get('backoffice/profile/all'

    );
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a recuperare profile', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
};


/** GET profile by ID */
export const getProfileById = async (id: string): Promise<ApiResponse<ProfileResponse>> => {
  try {
    let res = await api.get('backoffice/profile/' + id);
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a recuperare il mento da analizzare', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
};



/** PUT profile by ID */
export const saveProfile = async (
  data: ProfileSend
): Promise<ApiResponse<any>> => {

  try {
    let res = await api.put('backoffice/profile/' + data._id,
      {
        profile: data.profile,
        training: data.training
      });
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a inviare il risultato', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
};

/** DELETE profile by ID */
export const deleteProfile = async (
  _id: ProfileSend['_id']
): Promise<ApiResponse<any>> => {

  try {
    let res = await api.put('backoffice/profile/' + _id,
      {
        deleted: true
      });
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a eliminare', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
};