import api from ".";
import { ApiResponse, Statistics } from "../types/api";
import { MyError } from "../utils/MyError";

/** GET AICROMIA BOOKING STATISTIC */
export const getAicromiaBookingStatistic = async (

): Promise<ApiResponse<Statistics>> => {
  try {
    let res = await api.get('backoffice/statistics');
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a recuperare le statistiche', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
}


export const getAllStatistics = async (
  startDate?: Date | undefined | null,
  endDate?: Date | undefined | null
): Promise<ApiResponse<Statistics>> => {
  let res;
  try {

    if (startDate && endDate) {
      res = await api.post('backoffice/statistics/date', {
        startDate: startDate,
        endDate: endDate
      });
    } else {
      res = await api.get('backoffice/statistics');
    }
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a recuperare le statistiche', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
}