import React from 'react';
import StatisticSection from '../components/section/StatisticSection';

function Statistic() {


  return (
    <div className='page w-full mt-8'>
      <StatisticSection />
    </div>
  );
}

export default Statistic;
