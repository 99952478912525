import React, { useEffect, useRef } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import { useAuth } from '../../context/AuthContext';
import { NAVIGATION } from '../../constant/data';
import LinkButton from '../link/LinkButton';
import { useLocation } from 'react-router-dom';

function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<any>();

  // Context
  const { user, logout } = useAuth();

  const location = useLocation();

  useEffect(() => {
    onClose();
  }, [location, onClose]);

  return (
    <header className='sticky top-0'>

      {/** DESKTOP */}
      <div className='hidden  justify-end items-center bg-slate-100 py-4 md:flex'>

        <Menu>
          <MenuButton
            colorScheme='purple'
            as={Button} rightIcon={<ChevronDownIcon />}>
            Profile
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                logout()
              }}

            >Logout</MenuItem>

          </MenuList>
        </Menu>
        <Avatar
          name={user?.email ? user.email : ''}
          src={user?.photoURL ? user.photoURL : ''}
          size={'md'} mx={4}>
        </Avatar>


      </div>

      {/** MOBILE */}
      <div className='flex justify-between bg-slate-100 py-4 md:hidden'>
        <Button mx={4} ref={btnRef} colorScheme='purple' onClick={onOpen}>
          <HamburgerIcon />
        </Button>
        <Avatar
          name={user?.email ? user.email : ''}
          src={user?.photoURL ? user.photoURL : ''}
          size={'md'} mx={4}>
        </Avatar>


      </div>

      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menù</DrawerHeader>

          <DrawerBody>
            <div className='grid grid-cols-1 gap-2'>
              {NAVIGATION.map((n) => {
                return (
                  <LinkButton key={n.href} href={n.href} icon={n.icon}>
                    {n.label}
                  </LinkButton>
                );
              })}
            </div>
          </DrawerBody>

          <DrawerFooter className='border flex justify-center'>
            <div className='flex items-center space-x-4 justify-center w-full'>
              <Avatar
                name={user?.email ? user.email : ''}
                src={user?.photoURL ? user.photoURL : ''}
                size={'sm'} >
              </Avatar>
              <span>{user?.email}</span>
            </div>
          </DrawerFooter>

        </DrawerContent>
      </Drawer>
    </header>
  );
}

export default Header;
