// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react"

// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
  colors: {
    primary: {
      100: "#501187",
    },
    summer: {
      100: '#7858a8',
    },
    autumn: {
      100: '#dc4000',
    },
    winter: {
      100: '#9520e4',
    },
    spring: {
      100: '#fc130c',
    },
  },
  components: {

    Button: {

      variants: {
        solid: {
          bg: "primary.100",
          color: "white",
        },
      },
    },
  },
})

