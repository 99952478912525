import React, { useEffect, useState } from 'react'

//import 'react-modern-calendar-datepicker/lib/DatePicker.css';
//import { Calendar, DayRange } from 'react-modern-calendar-datepicker';
import { ArrowRightIcon } from '@chakra-ui/icons';

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar, DayRange } from '@hassanmojab/react-modern-calendar-datepicker';

import {
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react'
import { Statistics } from '../../types/api';
import { getAllStatistics } from '../../service/statistics';


function StatisticSection() {

  // State

  // init from and to with today date
  const today = new Date();

  const [dayRange, setDayRange] = useState<DayRange>({
    from: null,
    to: null
  });
  const [statistic, setStatistic] = useState<Statistics | null>(null);


  // Function
  const getStat = async () => {

    const from = new Date()
    const to = new Date()

    if (dayRange.from && dayRange.to) {
      from.setUTCFullYear(dayRange.from.year, dayRange.from.month - 1, dayRange.from.day)
      from.setUTCHours(0);
      from.setUTCMinutes(0);
      from.setUTCSeconds(0);
      from.setUTCMilliseconds(0);

      to.setUTCFullYear(dayRange.to.year, dayRange.to.month - 1, dayRange.to.day)
      to.setUTCHours(0);
      to.setUTCMinutes(0);
      to.setUTCSeconds(0);
      to.setUTCMilliseconds(0);
    }

    const { response, error } = await getAllStatistics(
      dayRange.from && from,
      dayRange.to && to
    );
    if (error) {
      console.log('error', error);

      return;
    }
    if (response) {
      console.log('response', response);
      setStatistic(response.data);
    }

  }

  // Call API to get data when dayRange is changed
  useEffect(() => {
    console.log(dayRange)

    try {
      getStat();
    }
    catch (error) {
      console.log('error', error);
    }

  }, [dayRange]);


  return (
    <div className=' flex flex-col md:flex-row md:justify-center md:gap-4 items-center z-0'>
      <Calendar
        value={dayRange}
        onChange={setDayRange}
        shouldHighlightWeekends
        calendarClassName=' text-center w-full'
        calendarRangeStartClassName='!bg-pink-200'
        calendarRangeEndClassName='!bg-blue-200'
      />
      <div>
        <div className='flex bg-slate-50 rounded-lg p-2 items-center justify-center space-x-2 my-4'>
          {
            dayRange.from &&
            <p className=' bg-pink-200 p-2 rounded-md'>{dayRange.from?.day}/{dayRange.from?.month}/{dayRange.from?.year}</p>
          }
          {
            dayRange.to &&
            <>
              <ArrowRightIcon
                h={3}
              />
              <p className='bg-blue-100 p-2 rounded-md'> {dayRange.to?.day}/{dayRange.to?.month}/{dayRange.to?.year}</p>
            </>
          }
        </div>

        <section className='grid grid-cols-1 gap-4'>


          {
            statistic &&
            <div className='border p-4 rounded-md'>
              <h3 className='text-2xl mb-2 font-semibold'>Aicromia</h3>
              <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                <Stat>
                  <StatLabel>Aicromia totali</StatLabel>
                  <StatNumber>{statistic.allAicromiaBooking}</StatNumber>
                </Stat>

                <Stat>
                  <StatLabel>Aicromia verificati</StatLabel>
                  <StatNumber>{statistic.aicromiaBookingVerified}</StatNumber>
                </Stat>

                <Stat>
                  <StatLabel>Aicromia non verificati</StatLabel>
                  <StatNumber>{statistic.aicromiaBookingNotVerified}</StatNumber>
                </Stat>

                <Stat>
                  <StatLabel>Aicromia immagini non valide</StatLabel>
                  <StatNumber>{statistic.aicromiaBookingImagesNotValid}</StatNumber>
                </Stat>
              </div>
            </div>
          }

          {
            statistic &&
            <div className='border p-4 rounded-md'>
              <h3 className='text-2xl mb-2 font-semibold'>Utenti</h3>
              <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                <Stat>
                  <StatLabel>Utenti totali</StatLabel>
                  <StatNumber>{statistic.allUser}</StatNumber>
                </Stat>

                <Stat>
                  <StatLabel>Utenti verificati</StatLabel>
                  <StatNumber>{statistic.allUserVerified}</StatNumber>
                </Stat>

                <Stat>
                  <StatLabel>Utenti newsletter</StatLabel>
                  <StatNumber>{statistic.allUserNewsletter}</StatNumber>
                </Stat>
              </div>
            </div>
          }

          {
            statistic &&
            <div className='border p-4 rounded-md'>
              <h3 className='text-2xl mb-2 font-semibold'>Wardrobe</h3>
              <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                <Stat>
                  <StatLabel>Wardrobe totali</StatLabel>
                  <StatNumber>{statistic.allWardrobe}</StatNumber>
                </Stat>


              </div>
            </div>
          }


        </section>
      </div>

    </div>
  )
}

export default StatisticSection