import { Icon, Tooltip } from '@chakra-ui/react';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Link, NavLink } from 'react-router-dom';

interface NavItemProps {
  icon: any;
  href: string;
  children: ReactNode;
}
const LinkButton = ({ icon, href, children }: NavItemProps) => {
  return (
    <NavLink
      className={({ isActive, isPending }) =>
        clsx(
          'flex items-center rounded-md  px-4 py-2 text-sm font-medium text-gray-800',
          isActive
            ? 'border border-primary bg-primary  text-white'
            : 'border border-primary text-primary hover:bg-primary hover:text-white',
          isPending ? 'pending' : isActive ? 'active' : ''
        )
      }
      to={href}
    >
      {icon && (
        <Icon
          mr={children ? 2 : 0}
          fontSize='16'
          _groupHover={{
            color: 'white',
          }}
          as={icon}
        />
      )}
      {children}
    </NavLink>
  );
};

export default LinkButton;
