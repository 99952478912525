import React, { useEffect, useState } from 'react';
import { getAllAicromia } from '../service/aicromia';
import { AicromiaResponse } from '../types/api';
import { toast } from 'react-hot-toast';
import { MyError } from '../utils/MyError';
import { Skeleton } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import AicromiaItem from '../components/card/AicromiaItem';
import AicromiaFilter from '../components/filter/AicromiaFilter';
import { AicromiaFilterType } from '../types/generic';

function AicromiaBooking() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AicromiaResponse[]>([]);

  const [filter, setFilter] = useState<AicromiaFilterType | null>(null);
  const filterData = async (reset?: boolean) => {

    setLoading(true);
    const { response, error } = await getAllAicromia(
      reset ? undefined :
        filter);
    if (error) {
      console.log('error', error);
      if (error instanceof MyError) {
        toast.error(error.message);
      } else {
        toast.error('Errore');
      }
      setLoading(false);
      return;
    }
    if (response) {
      console.log('response', response);
      setData(response.data);
    }
    setLoading(false);
  };


  useEffect(() => {
    filterData();
  }, []);

  if (loading) {
    return (
      <div className='mx-4 grid grid-cols-1 gap-2'>
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
        <Skeleton height='50px' />
      </div>
    );
  }

  return (
    <div className='page w-full'>
      <AicromiaFilter
        filter={filter}
        setFilter={setFilter}
        filterData={filterData}

      />
      <section className='grid grid-cols-1 gap-2 m-4'>
        {data.map((item) => {
          return <AicromiaItem {...item} />;
        })}
      </section>

      <Outlet />
    </div>
  );
}

export default AicromiaBooking;
