import React from 'react';
import { AicromiaBookingResponse } from '../../types/api';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

function AicromiaBookingItem({
  season,
  seasonImages,
  _id,
  userDetail
}: AicromiaBookingResponse) {
  return (
    <Link to={'/aicromia-booking/' + _id}>
      <div className='flex md:flex-row flex-col w-full  space-x-4  rounded-lg bg-slate-100 p-4 hover:shadow-md'>
        <img
          alt={'Image aicromia'}
          src={seasonImages[0].imageUrl} className='h-[12vh] w-[12vh] rounded-md' />
        <div className='flex flex-col m-2'>
          <p>
            Stagione: <span className='font-bold'>{season.seasonName}</span>
          </p>
          <p>
            Sotto Stagione:{' '}
            <span className='font-bold'>{season.underseason}</span>
          </p>
          {
            season.confidence &&

            <div className='flex items-center space-x-2'>
              <span>Confidence: {season.confidence.toPrecision(2)}</span>
              <div
                className={clsx(
                  parseFloat(season.confidence.toPrecision(2)) <= 0.25 && 'red',
                  parseFloat(season.confidence.toPrecision(2)) > 0.25 &&
                  parseFloat(season.confidence.toPrecision(2)) < 0.5 &&
                  'yellow',
                  parseFloat(season.confidence.toPrecision(2)) >= 0.5 &&
                  parseFloat(season.confidence.toPrecision(2)) < 0.75 &&
                  'lightgreen',
                  parseFloat(season.confidence.toPrecision(2)) >= 0.75 && 'green',

                  'rounded-full'
                )}
              >
                <progress value={season.confidence.toPrecision(2)} max={1} />
              </div>
            </div>
          }
        </div>
        <div className='m-2'>
          <p>{userDetail.name} {userDetail.surname}</p>
          <p>{userDetail.email}</p>
          <p>{userDetail.season?.seasonName} {userDetail.season?.underseason}</p>
        </div>
      </div>
    </Link>
  );
}

export default AicromiaBookingItem;
