import axios, { AxiosHeaders } from 'axios';
import { auth } from '../firebase/auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(async config => {
  if (!auth.currentUser) return config;

  (config.headers as AxiosHeaders).set(
    'Authorization',
    `Bearer ${await auth.currentUser.getIdToken()}`,
  )
  return config
}, (error) => {
  return Promise.reject(error)
})

export default api;
