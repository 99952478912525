import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { MyError } from '../utils/MyError';
import { ProfileResponse } from '../types/api';
import { Button, Skeleton, useDisclosure } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Image
} from '@chakra-ui/react';

import { Profile } from '../types/generic';
import { PROFILE_VALUE } from '../constant/data';
import { DeleteIcon } from '@chakra-ui/icons';
import { deleteProfile, getProfileById, saveProfile } from '../service/profile';

function ProfileDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);
  const [training, setTraining] = useState<boolean>(true);
  const [data, setData] = useState<ProfileResponse>();
  const [selectedResult, setSelectedResult] = useState<Profile>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getData = async () => {
    setLoading(true);
    if (!id) {
      setLoading(false);
      return;
    }

    const { response, error } = await getProfileById(id);
    if (error) {
      if (error instanceof MyError) {
        toast.error(error.message);
      } else {
        toast.error('Errore');
      }
      setLoading(false);
      return;
    }

    if (response && response.data) {
      setData(response.data);
      setLoading(false);
    }

    setLoading(false);
  };

  const handleConfirmResponseModal = (profile: Profile) => {
    setSelectedResult(profile);
    onOpen();
  };

  const submitConfirmResponse = async () => {
    setIsSubmitting(true);

    if (!id) return;
    if (!selectedResult) return;

    const { response, error } = await saveProfile({
      _id: id,
      profile: selectedResult,
      training: true
    })

    if (error) {
      if (error instanceof MyError) {
        toast.error(error.message);
        setIsSubmitting(false);
        return;
      } else {
        toast.error('Errore');
        setIsSubmitting(false);
        return;
      }
    }

    if (response) {
      toast.success('Risposta inviata correttamente');
      navigate('/profile');
    }

    setIsSubmitting(false);
  };

  const deleteSingleProfile = async () => {
    setIsSubmitting(true);

    if (!id) return;

    const { response, error } = await deleteProfile(id)

    if (error) {
      if (error instanceof MyError) {
        toast.error(error.message);
        setIsSubmitting(false);
        return;
      } else {
        toast.error('Errore');
        setIsSubmitting(false);
        return;
      }
    }

    if (response) {
      toast.success('Eliminata correttamente');
      navigate('/profile');
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className='w-full border p-4'>
      {loading && !data ? (
        <div className='m-4'>
          <Skeleton height='70px' />
        </div>
      ) : (
        <div className='relative'>
          <div className=' sticky top-0 z-10 h-full bg-white  bg-opacity-70 pb-2 backdrop-blur-sm'>
            <div className=' sticky top-0 z-10 h-full'>
              <Link className=' underline' to={'/profile'}>
                Indietro
              </Link>
            </div>

          </div>

          <div className='m-2'>

            <section className='flex flex-col md:space-x-8  md:flex-row items-center md:items-start md:justify-start'>
              <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden'>
                <Image src={data?.imageUrl} alt={"image"} />

              </Box>
              <div className='flex flex-col space-y-4'>
                <h1 className='text-2xl font-bold'>Seleziona mento</h1>
                <div className='grid grid-cols-2 md:grid-cols-3 gap-2'>
                  {
                    PROFILE_VALUE.map((f: any) => {
                      return <Button
                        onClick={() => handleConfirmResponseModal(f.value)}
                        colorScheme='purple'
                      >{f.name}</Button>
                    })
                  }
                </div>
                <div className='w-full'>
                  <Button
                    className='w-full'
                    color={'white'}
                    colorScheme='red'
                    bg={'red.500'}
                    onClick={() => deleteSingleProfile()}
                    isLoading={isSubmitting && !isOpen}
                  >
                    <DeleteIcon

                      className='mr-2 ' />Elimina
                  </Button>
                </div>
              </div>
            </section>

          </div>

          {/** NEW */}



          {/** END */}
        </div>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Conferma la risposta</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            <div>
              Risposta: {selectedResult}
            </div>



          </ModalBody>

          <ModalFooter>
            <Button variant={'ghost'} mr={3} onClick={onClose}>
              Chiudi
            </Button>
            <Button
              isLoading={isSubmitting}
              onClick={submitConfirmResponse}
              colorScheme='primary'
            >
              Conferma
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ProfileDetail;
