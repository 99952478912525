import { Button, Input } from '@chakra-ui/react';
import React, { useEffect } from 'react'
import { AicromiaFilterType } from '../../types/generic';

type Props = {
  filter: AicromiaFilterType | null;
  setFilter: React.Dispatch<React.SetStateAction<AicromiaFilterType | null>>;
  filterData: (reset?: boolean) => Promise<void>
}

function AicromiaFilter({ filter, setFilter, filterData }: Props) {

  const resetFilter = async () => {
    setFilter(null);
    sessionStorage.removeItem('filter');
    await filterData(true);
  }

  const addFilter = (filter: AicromiaFilterType) => {
    sessionStorage.setItem('filter', JSON.stringify(filter));
    setFilter(filter);
  }

  return (
    <div className='py-4 mx-4 bg-slate-50 mt-4 rounded-md p-2 -z-10'>
      <p className='pb-2'>Filtri:</p>
      <div className='grid grid-cols-1 md:grid-cols-4 gap-4'>
        <Input
          placeholder="Select Date"
          size="md"
          value={filter?.date || ''}
          type="date"
          className=''
          onChange={(e) => {
            addFilter({
              ...filter,
              date: e.target.value,
            });
          }}
        />
      </div>

      <div className='flex space-x-4'>
        <Button
          onClick={() => resetFilter()}
          variant={'ghost'} className='mt-4'>
          Reset
        </Button>
        <Button
          onClick={() => filterData()}
          isDisabled={filter === null ? true : false} colorScheme='purple' className='mt-4'>
          {
            filter ? 'Cerca' : 'Applica filtri'
          }
        </Button>
      </div>
    </div>
  )
}

export default AicromiaFilter