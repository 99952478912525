import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';

function DashboardWrapper() {
  return (
    <div className='flex   flex-col md:flex-row'>
      <div className='hidden md:block'>
        <Sidebar />
      </div>
      <div className='w-full '>
        <Header />
        <Outlet />
      </div>

    </div>
  );
}

export default DashboardWrapper;
