import api from ".";
import { AicromiaBookingResponse, AicromiaResponse, ApiResponse } from "../types/api";
import { AicromiaFilterType, Season } from "../types/generic";
import { MyError } from "../utils/MyError";

/** GET ALL aicromia BOOKING */
export const getAllAicromiaBooking = async (
  filter?: AicromiaFilterType | undefined | null
): Promise<ApiResponse<AicromiaBookingResponse[]>> => {
  try {
    let res = await api.get('backoffice/aicromia/booking/all' + (filter?.date ? '?date=' + filter.date : ''));
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a recuperare le prenotazioni', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
};

/** GET ALL aicromia BOOKING */
export const getAllAicromiaBookingVerified = async (
  filter?: AicromiaFilterType | undefined | null
): Promise<ApiResponse<AicromiaBookingResponse[]>> => {
  try {
    let res = await api.get('backoffice/aicromia/booking/verified/all' + (filter?.date ? '?date=' + filter.date : ''));
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a recuperare le prenotazioni', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
};

/** GET ALL aicromia */
export const getAllAicromia = async (filter?: AicromiaFilterType | undefined | null): Promise<ApiResponse<AicromiaResponse[]>> => {
  try {
    let res = await api.get('backoffice/aicromia/all'
      + (filter?.date ? '?date=' + filter.date : '')
    );
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a recuperare AICROMIA', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
};


/** GET aicromia BOOKING by ID */
export const getAicromiaBookingById = async (id: string): Promise<ApiResponse<AicromiaBookingResponse>> => {
  try {
    let res = await api.get('backoffice/aicromia/booking/' + id);
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a recuperare la prenotazione', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
};

/** GET aicromia BOOKING by ID */
export const getAicromiaById = async (id: string): Promise<ApiResponse<AicromiaResponse>> => {
  try {
    let res = await api.get('backoffice/aicromia/' + id);
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a recuperare la prenotazione', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
};


/** PUT aicromia BOOKING by ID */
export const saveAicromiaBookingSeason = async (
  id: string,
  training: boolean,
  season?: Season,
  imagesNotValid?: boolean
): Promise<ApiResponse<any>> => {

  const data = {
    season: season,
    training: training,
    imagesNotValid: imagesNotValid
  }

  try {
    let res = await api.put('backoffice/aicromia/booking/' + id, data);
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a inviare il risultato', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
};
