import React from 'react';
import { AicromiaBookingResponse, AicromiaResponse } from '../../types/api';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

function AicromiaItem({
  training,
  season,
  createdAt,
  imageUrl1,
  imageUrl2,
  imageUrl3,
  _id,
  user,
}: AicromiaResponse) {
  return (
    <Link to={'/aicromia/' + _id}>
      <div className='flex space-x-4 rounded-lg bg-slate-100 p-4 hover:shadow-md'>
        <img src={imageUrl1} className='h-[12vh] w-[12vh] rounded-md' />
        <div className='flex flex-col '>
          <p>
            Stagione: <span className='font-bold'>{season.seasonName}</span>
          </p>
          <p>
            Sotto Stagione:{' '}
            <span className='font-bold'>{season.underseason}</span>
          </p>
          <div className='flex items-center space-x-2'>
            {
              season.confidence && <>

                <span>Confidence: {season.confidence.toPrecision(2)}</span>
                <div
                  className={clsx(
                    parseFloat(season.confidence.toPrecision(2)) <= 0.25 && 'red',
                    parseFloat(season.confidence.toPrecision(2)) > 0.25 &&
                    parseFloat(season.confidence.toPrecision(2)) < 0.5 &&
                    'yellow',
                    parseFloat(season.confidence.toPrecision(2)) >= 0.5 &&
                    parseFloat(season.confidence.toPrecision(2)) < 0.75 &&
                    'lightgreen',
                    parseFloat(season.confidence.toPrecision(2)) >= 0.75 && 'green',

                    'rounded-full'
                  )}
                >
                  <progress value={season.confidence && season.confidence.toPrecision(2)} max={1} />
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </Link>
  );
}

export default AicromiaItem;
