import api from ".";
import { ApiResponse, FaceshapeFrontResponse, FaceshapeFrontSend } from "../types/api";
import { MyError } from "../utils/MyError";

/** GET ALL faceshape */
export const getAllFaceshape = async (): Promise<ApiResponse<FaceshapeFrontResponse[]>> => {
  try {
    let res = await api.get('backoffice/faceshape/all'

    );
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a recuperare FACESHAPE', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
};


/** GET aicromia BOOKING by ID */
export const getFaceshapeById = async (id: string): Promise<ApiResponse<FaceshapeFrontResponse>> => {
  try {
    let res = await api.get('backoffice/faceshape/' + id);
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a recuperare la prenotazione', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
};



/** PUT faceshape by ID */
export const saveFaceshape = async (
  data: FaceshapeFrontSend
): Promise<ApiResponse<any>> => {

  try {
    let res = await api.put('backoffice/faceshape/' + data._id,
      {
        faceshape: data.faceShape
      });
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a inviare il risultato', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
};

/** DELETE faceshape by ID */
export const deleteFaceshape = async (
  _id: FaceshapeFrontSend['_id']
): Promise<ApiResponse<any>> => {

  try {
    let res = await api.put('backoffice/faceshape/' + _id,
      {
        deleted: true
      });
    if (res.data) {
      return { response: res, error: undefined };
    } else {
      return {
        response: undefined,
        error: new MyError('Non riesco a inviare il risultato', 'API_ERROR'),
      };
    }
  } catch (e) {
    return {
      response: undefined,
      error: new MyError('Non riesco a contattare il server', 'API_ERROR'),
    };
  }
};