import { Profile, Faceshape, Menu, Season } from "../types/generic";

import { FaHome, FaBrain, FaPalette } from 'react-icons/fa'
import { FcStatistics } from 'react-icons/fc'
import { MdFaceRetouchingNatural } from "react-icons/md";
import { GiPlagueDoctorProfile } from 'react-icons/gi'

export const NAVIGATION: Menu[] = [
  {
    label: "Home",
    href: "/",
    icon: FaHome,
  },
  {
    label: "Aicromia booking",
    href: "/aicromia-booking",
    icon: FaBrain,
  },
  {
    label: "Aicromia booking Verified",
    href: "/aicromia-booking/verified",
    icon: FaBrain,
  },
  {
    label: "Aicromia",
    href: "/aicromia",
    icon: FaPalette,
  },
  {
    label: "Statistic",
    href: "/statistic",
    icon: FcStatistics,
  },
  {
    label: "Faceshape",
    href: "/faceshape",
    icon: MdFaceRetouchingNatural,
  },
  {
    label: "Profil",
    href: "/profile",
    icon: GiPlagueDoctorProfile,
  }
]

export const SEASON_VALUE: Season[] = [
  {
    seasonName: 'summer',
    underseason: 'light'
  },
  {
    seasonName: 'summer',
    underseason: 'cool'
  },
  {
    seasonName: 'summer',
    underseason: 'soft'
  },
  {
    seasonName: 'summer',
    underseason: 'absolute'
  },
  {
    seasonName: 'winter',
    underseason: 'deep'
  },
  {
    seasonName: 'winter',
    underseason: 'cool'
  },
  {
    seasonName: 'winter',
    underseason: 'bright'
  },
  {
    seasonName: 'winter',
    underseason: 'absolute'
  },
  {
    seasonName: 'spring',
    underseason: 'light'
  },
  {
    seasonName: 'spring',
    underseason: 'warm'
  },
  {
    seasonName: 'spring',
    underseason: 'bright'
  },
  {
    seasonName: 'spring',
    underseason: 'absolute'
  },
  {
    seasonName: 'autumn',
    underseason: 'deep'
  },
  {
    seasonName: 'autumn',
    underseason: 'warm'
  },
  {
    seasonName: 'autumn',
    underseason: 'soft'
  },
  {
    seasonName: 'autumn',
    underseason: 'absolute'
  },

]

export const FACESHAPE_VALUE: {
  name: Faceshape,

  value: string
}[] = [
    {
      name: 'cuore',
      value: 'cuore'
    },
    {
      name: 'triangolo inverso',
      value: 'triangolo_inverso'
    },
    {
      name: 'ovale',
      value: 'ovale'
    }, {
      name: 'tondo',
      value: 'tondo'
    }, {
      name: 'oblungo',
      value: 'oblungo'
    }, {
      name: 'quadrato',
      value: 'quadrato'
    }, {
      name: 'diamante',
      value: 'diamante'
    }, {
      name: 'triangolo',
      value: 'triangolo'
    }
  ]

export const PROFILE_VALUE: {
  name: Profile,
  value: string
}[] = [
    {
      name: 'Mediamente strutturato',
      value: 'mediamente_strutturato'
    },
    {
      name: 'Strutturato',
      value: 'strutturato'
    },
    {
      name: 'Dolce',
      value: 'dolce'
    },
  ]