import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
//Router
import { BrowserRouter } from 'react-router-dom';
import AuthContextProvider from './context/AuthContext';
import { theme } from './styles/theme';
import { Toaster } from 'react-hot-toast';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(

  <AuthContextProvider>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Toaster />
        <App />
      </ChakraProvider>
    </BrowserRouter>
  </AuthContextProvider>

);
