import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

type Props = {
  children: JSX.Element;
};

const PrivateRoute = ({ children }: Props) => {
  //context
  const { user, loading } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      if (user == undefined || user == null) {
        navigate('/login');
      }
    }
  });

  if (!user || loading) {
    return <div>Loading...</div>;
  }

  return children;
};

export default PrivateRoute;
