import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import clsx from 'clsx';
import { useState } from 'react';
import { NAVIGATION } from '../../constant/data';
import LinkButton from '../link/LinkButton';

function Sidebar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div
      className={clsx(
        sidebarOpen ? 'w-full' : 'w-max',
        ' sticky top-0  min-h-screen max-w-[15vw] transform bg-slate-100 transition-all duration-300  md:block'
      )}
    >
      <div className='mx-4 grid grid-cols-1 gap-2'>
        <button
          className='text-end'
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {sidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </button>

        {NAVIGATION.map((n) => {
          return (
            <LinkButton key={n.href} href={n.href} icon={n.icon}>
              {sidebarOpen ? n.label : null}
            </LinkButton>
          );
        })}
      </div>
    </div>
  );
}

export default Sidebar;
