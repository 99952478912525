export type ErrorName = 'API_ERROR' | 'AUTH_ERROR' | 'OTHER_ERROR';

export class MyError extends Error {
  name: ErrorName;
  message: string;

  constructor(message: string, name: ErrorName) {
    super(message);
    this.name = name;
    this.message = message;
  }
}
