import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  getAicromiaBookingById,
  saveAicromiaBookingSeason,
} from '../service/aicromia';
import { toast } from 'react-hot-toast';
import { MyError } from '../utils/MyError';
import { AicromiaBookingResponse } from '../types/api';
import { Button, Checkbox, Skeleton, useDisclosure, Stack, Badge } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Image
} from '@chakra-ui/react';

import { MdOutlineKeyboardDoubleArrowDown } from 'react-icons/md';
import { Season } from '../types/generic';
import clsx from 'clsx';

function AicromiaBookingDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenUser, onOpen: onOpenUser, onClose: onCloseUser } = useDisclosure();


  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AicromiaBookingResponse>();
  const [selectedResult, setSelectedResult] = useState<Season>();
  const [training, setTraining] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [imagesNotValid, setImagesNotValid] = useState<boolean>(false);

  const getData = async () => {
    setLoading(true);
    if (!id) {
      setLoading(false);
      return;
    }

    const { response, error } = await getAicromiaBookingById(id);
    if (error) {
      if (error instanceof MyError) {
        toast.error(error.message);
      } else {
        toast.error('Errore');
      }
      setLoading(false);
      return;
    }

    if (response) {
      setData(response.data);
      setLoading(false);
    }

    setLoading(false);
  };

  const handleConfirmResponseModal = (season: Season) => {
    setSelectedResult(season);
    onOpen();
  };

  const submitConfirmResponse = async () => {
    setIsSubmitting(true);

    if (!id) return;
    if (!selectedResult) return;

    const { response, error } = await saveAicromiaBookingSeason(
      id,
      training,
      selectedResult
    );

    if (error) {
      if (error instanceof MyError) {
        toast.error(error.message);
        setIsSubmitting(false);
        return;
      } else {
        toast.error('Errore');
        setIsSubmitting(false);
        return;
      }
    }

    if (response) {
      toast.success('Risposta inviata correttamente');
      navigate('/aicromia-booking');
    }

    setIsSubmitting(false);
  };

  const submitImagesNotValidResponse = async () => {
    setIsSubmitting(true);

    if (!id) return;

    const { response, error } = await saveAicromiaBookingSeason(
      id,
      false,
      undefined,
      true
    );

    if (error) {
      if (error instanceof MyError) {
        toast.error(error.message);
        setIsSubmitting(false);
        return;
      } else {
        toast.error('Errore');
        setIsSubmitting(false);
        return;
      }
    }

    if (response) {
      toast.success('Risposta inviata correttamente');
      navigate('/aicromia-booking');
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className='w-full border p-4'>
      {loading && !data ? (
        <div className='m-4'>
          <Skeleton height='70px' />
        </div>
      ) : (
        <div className='relative'>
          <div className=' sticky top-0 z-10 h-full bg-white  bg-opacity-70 pb-2 backdrop-blur-sm'>
            <div className=' sticky top-0 z-10 h-full'>
              <Link className=' underline' to={'/aicromia-booking'}>
                Indietro
              </Link>
            </div>
            <h3 className='text-center text-xl font-bold'>Dettaglio</h3>
            <div className=' flex flex-col items-center justify-center'>
              <p className=''>
                Email: {data?.userDetail.email}{' '}

              </p>
              <p className=''>
                Risultato AI: {data?.season.seasonName}{' '}
                {data?.season.underseason}
              </p>
              <p>Confidence: {data?.season.confidence && data?.season.confidence.toPrecision(2)}</p>
              <p onClick={() => onOpenUser()} className='underline cursor-pointer'>Info utente</p>
            </div>
          </div>

          <div className='m-2'>
            <div className='justify-center grid grid-cols-3 gap-4' >
              {data?.seasonImages.map(
                (d, i) => {

                  return (
                    <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden'>
                      <Image src={d.imageUrl} alt={"image"} />
                      <Box px='6' pt='6'>
                        <Box
                          mt='1'
                          fontWeight='semibold'
                          as='h4'
                          lineHeight='tight'
                          noOfLines={1}
                        >
                          Immagine {i + 1}
                        </Box>
                      </Box>

                      <div className='flex flex-col mx-6 my-2'>
                        <p>
                          Stagione: <Badge
                            color={'white'}
                            colorScheme={d.seasonName}>{d.seasonName}</Badge>
                        </p>
                        <p>
                          Sotto Stagione:{' '}
                          <span className='font-bold'>{d.underseason}</span>
                        </p>
                        <div className='flex items-center space-x-2 flex-col md:flex-row'>
                          <p>Confidence: {d.confidence.toPrecision(2)}</p>
                          <div
                            className={clsx(
                              parseFloat(d.confidence.toPrecision(2)!) <= 0.25 && 'red',
                              parseFloat(d.confidence.toPrecision(2)!) > 0.25 &&
                              parseFloat(d.confidence.toPrecision(2)!) < 0.5 &&
                              'yellow',
                              parseFloat(d.confidence.toPrecision(2)!) >= 0.5 &&
                              parseFloat(d.confidence.toPrecision(2)!) < 0.75 &&
                              'lightgreen',
                              parseFloat(d.confidence.toPrecision(2)!) >= 0.75 && 'green',

                              'rounded-full   '
                            )}
                          >
                            <progress
                              className=''
                              value={d.confidence.toPrecision(2)!} max={1} />
                          </div>
                        </div>
                      </div>
                    </Box>
                  )
                })
              }
            </div>
            <div className='flex justify-center m-4'>
              <Button colorScheme='red' color={'white'} size={'lg'} onClick={() => submitImagesNotValidResponse()}>&#9888; IMMAGINI NON VALIDE &#9888;</Button>
            </div>
          </div>



          {[data?.filteredImage1, data?.filteredImage2, data?.filteredImage3].map(
            (filteredImage: any, imageNumber: number) => {
              if (!filteredImage) return <></>
              return (
                <>
                  <h3 className='py-4 pt-8 text-center text-xl font-bold'>
                    Immagine {imageNumber + 1}
                  </h3>
                  <div className='grid grid-cols-2 place-items-center gap-4 bg-slate-100 p-2  md:grid-cols-4'>
                    {
                      Object.keys(filteredImage).map((key: any, index) => {


                        const arrayData = filteredImage[key]

                        return arrayData.length === 0 ? <></> :
                          arrayData.map((element: any) => {
                            return <div className='rounded-md bg-white p-1'>
                              <img
                                className='h-[30vh] rounded object-contain'
                                src={element.url}
                              />
                              <div className='flex justify-center space-x-2 py-2'>
                                <span>{key}</span>
                                <span>{element.underseason}</span>
                              </div>


                              {
                                key === 'contrasto' ? <></> :
                                  <Button
                                    onClick={() => handleConfirmResponseModal({ seasonName: key, underseason: element.underseason })}
                                    className='w-full'
                                    size={'sm'}
                                    colorScheme={'primary'}
                                  >
                                    Conferma
                                  </Button>
                              }

                            </div>
                          })

                      })
                    }

                  </div>
                </>
              );
            }
          )}





        </div>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Conferma la risposta</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className='flex flex-col items-center justify-center rounded-md border py-4'>
              <div className='flex items-center p-1'>
                <p>
                  Da:{' '}
                  <span className='rounded-full bg-red-300 p-1'>
                    {data?.season.seasonName} {data?.season.underseason}
                  </span>
                </p>
              </div>
              <div className='flex items-center justify-center'>
                <MdOutlineKeyboardDoubleArrowDown />
              </div>
              <div className='flex'>
                <p className='rounded-full p-1'>
                  A:
                  <span className='rounded-full bg-green-300 p-1'>
                    {selectedResult?.seasonName} {selectedResult?.underseason}
                  </span>
                </p>
              </div>
            </div>
            <div className='pt-4'>
              <Checkbox isChecked={training} onChange={(e) => setTraining(e.target.checked)}>
                Training
              </Checkbox>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button variant={'ghost'} mr={3} onClick={onClose}>
              Chiudi
            </Button>
            <Button
              isLoading={isSubmitting}
              onClick={submitConfirmResponse}
              colorScheme='primary'
            >
              Conferma
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Modal isOpen={isOpenUser} onClose={onCloseUser}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Info utente</ModalHeader>
          <ModalBody>
            <div className='flex flex-col items-center justify-center rounded-md border py-4'>
              <p>Id: {data?.userDetail._id}</p>
              <p>Nome e cognome: {data?.userDetail.name} {data?.userDetail.surname}</p>
              <p>Email: {data?.userDetail.email}</p>
              <p>Moda: {data?.userDetail.fashionPreference}</p>
              <p>Stagione corrente: {data?.userDetail.season?.seasonName} {data?.userDetail.season?.underseason}</p>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default AicromiaBookingDetail;
