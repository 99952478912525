import {
  getAuth,
  signInWithEmailAndPassword,
  User,
  UserCredential,
} from 'firebase/auth';
import React, { createContext, useContext } from 'react';
import { type ReactNode } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseApp } from '../firebase/auth';

//types
interface AuthContextProps {
  children: ReactNode;
}

interface AppContextInterface {
  user?: User | null;
  loading: boolean;
  logout: () => void;
  login: (
    email: string,
    password: string
  ) => Promise<UserCredential | undefined>;
}

// context init
export const AuthContext = createContext({} as AppContextInterface);
export const useAuth = () => useContext(AuthContext);
const auth = getAuth(firebaseApp);

// Provider
function AuthContextProvider({ children }: AuthContextProps) {
  // state for user
  const [user, loading] = useAuthState(auth);

  const login = async (email: string, password: string) => {
    try {
      let res = await signInWithEmailAndPassword(auth, email, password);
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    try {
      auth.signOut();
    } catch (error) {
      console.log(error);
    }

  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        login,
        logout
      }}
    >
      {loading ? <div>Loading...</div> : children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
